import React from 'react';
import PropTypes from 'prop-types';
import Helmet from '../components/Helmet';
import Layout from '../components/GatsbyLayout';

const aboutCookies = ({ location }) => (
  <Layout location={location}>
    <Helmet
      title="About Cookies"
      description="General information about cookies and how they are used"
      keywords={['cookies']}
    />
    <div css={{
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '75px 0'
    }}
    >
      <h1>ABOUT COOKIES</h1>
      <h2>General information about cookies and how they are used</h2>
      <i>
        Cookies are a piece of data sent from a web site and stored in your browsers memory.
        <p />
        Cookies can be required to allow a web to function properly as web sites are stateless
        (they don't remember user information from one internal page to another) but sometimes they are used to track
        user actions within one web or across multiple webs in order to collect usage information or identify interests
        and map behaviour to be able to offer relevant advertisements.
        <p />
        When webs use the CookieHub widget they offer you the choice to allow or deny certain cookie categories which
        helps protect your privacy. Below is a description on the most common cookie categories.
      </i>
      <hr />
      <h2>Necessary cookies</h2>
      <i>
        Neccessary cookies are used to make a web site function properly, they are generally not used for tracking and
        are not shared between webs.
        <p />
        The most common necessary action performed using a cookie is user authentication. When you log in to any web
        site, a cookie is stored in your browser including some piece of data used by the web to remember who is
        authenticated when you go to another internal page or refresh the site.
        <p />
        Cookies are also used so that a web knows what's in your shopping cart, store user settings and more.
      </i>
      <hr />
      <h2>Analytical cookies</h2>
      <i>
        Analytical cookies are not necessary but are used to collect valuable information on how a web site is being
        used, identify issues and figure out what needs to be improved on the site and what content is useful. The
        information collected is in most cases anonymous but some analytics services collect information that can be
        used to identify the user.
      </i>
      <hr />
      <h2>Marketing cookies</h2>
      <i>
        Marketing cookies are used to track visitors across websites to to profile user behavior and allow publishers
        to display relevant advertisements.
      </i>
    </div>
  </Layout>
);

aboutCookies.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

aboutCookies.defaultProps = {
  data: null
};


export default aboutCookies;
